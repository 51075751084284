function initBasketItemControls() {
    $('.spinner-amount').on('click', 'button', function (e) {
        var parent = $(this).parents('.spinner-amount');
        var input = parent.find('.amount');
        var amount = input.val();
        // var btn = parent.siblings('.btn');

        if (!parent.hasClass('table-amount')) {
            if (!$(this).is('.down')) {
                amount++
            } else {
                if (amount > 0) amount--
            }
        } else {
            if (!$(this).is('.down')) {
                amount++
            } else {
                if (amount > 0) amount--
            }

            if (parseInt(amount) === 0) {
                input.val(1).attr('value', 1);
            }
        }

        input.val(amount).attr('value', amount);
        input.change();
    });
}

var onBaskChange = function (cart) {
    var count = $('.cart .prod-num');
    var cnt = 0;
    if (cart.hasOwnProperty('cart')) {
        cnt = cart.cart.items;
    } else if (cart.hasOwnProperty('cartItems')) {
        cnt = cart.cartItems;
    }
    if (cnt) {
        count.removeClass('hidden');
        count.text(cnt)
    } else {
        count.addClass('hidden');
    }
};
var onBaskPreorderChange = function (cart) {
    var count = $('.preorderCart .prod-num');
    var cnt = 0;
    if (cart.hasOwnProperty('cart')) {
        cnt = cart.cart.items;
    } else if (cart.hasOwnProperty('cartItems')) {
        cnt = cart.cartItems;
    }
    if (cnt) {
        count.removeClass('hidden');
        count.text(cnt)
    } else {
        count.addClass('hidden');
    }
};

function initProductCardSlider(element) {
    element.find('.product-slider').owlCarousel({
        loop      : true,
        nav       : true,
        items     : 1,
        margin    : 0,
        dots      : false,
        autoHeight: true,
        autoWidth : false,
        navText   : ['', '']
    });
}

function initProductCard(element) {
    initBasketItemControls();
    element.find('.product-basket-item').each(function () {
        (new ProductCardItem($(this)))
            .onChange(onBaskChange);
    });
    initProductLinksListener(element.find('.s-similar a.similar-item'));
}

function initPreorderCard(element, preorderCount = null) {
    element.find('.preorder-product').each(function (i, v) {
        (new PreorderCardItem($(this)))
            .onChange(onBaskPreorderChange);
    });
}

function heightses(container) {
    if ($(window).width() >= 390) {
        container.find('.cat-item-title').matchHeight({byRow: true,});
    }
}

function initProductLinksListener(links) {
    links.unbind('click');
    links.click(function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        openProductPopup(href);
    });
}

function openProductPopup(href, noPush) {
    $.magnificPopup.close();
    $.magnificPopup.open({
        items          : {
            src : '#popup-catalog-product',
            type: 'inline',
        },
        closeOnBgClick : false,
        enableEscapeKey: false,
        callbacks      : {
            open : function () {
                var spinId = html.spinnerButtonShow($('#loadEditInfoButton'));
                var content = $(this.content).find('.catalog-product-popup-content');
                $.magnificPopup.prevLocation = new URL(document.location.href);
                content.empty();
                http.ajax(
                    href,
                    null,
                    (function (json) {
                        html.spinnerButtonHide(spinId);
                        if (json.htmlContent) {
                            content.html(json.htmlContent);
                            content.find('.product-slider .product-slide:first-child img')
                                .one('load', function () {
                                    initProductCardSlider(content);
                                });
                            initProductCard(content);
                            if (!noPush && href.search('/product/') !== -1) {
                                history.pushState(
                                    null,
                                    null,
                                    location.protocol + '//' + location.host + href);
                            }
                        }
                    }).bind(this)
                );
                $('.mfp-close, .mfp-close-btn-in').click(function (e) {
                    e.stopPropagation();
                    $.magnificPopup.close();
                });
                $('.mfp-content').click(function (e) {
                    e.stopPropagation();
                });
            },
            close: function () {
                if ($.magnificPopup.prevLocation.pathname.substr(0, 9) !== '/product/')
                    history.pushState(
                        null,
                        null,
                        $.magnificPopup.prevLocation.protocol + '//' + $.magnificPopup.prevLocation.host +
                        $.magnificPopup.prevLocation.pathname);
            }
        }
    }, 0);
}

$(document).ready(function () {
    window.onpopstate = function (event) {
        var l = new URL(document.location.href);
        if (l.pathname.substr(0, 9) === '/product/') {
            openProductPopup(document.location.href, true);
        } else {
            $.magnificPopup.close();
        }
    };

    $('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);
        }, 'xml');
    });

    /**
     * mobile-mnu customization
     */
    var mmenu = $('#mobile-mnu');
    if (mmenu.length > 0) {
        var menuLogo = mmenu.data("logo");
        var $mmenu = mmenu.mmenu({
            navbars     : [{
                content: ["<img src=" + menuLogo + " class=\"img-responsive mm-logo\" alt=\"alt\"/>"],
                height : 3
            }],
            "pageScroll": true,

            "navbar"    : {
                "title": "",
            },
            "extensions": [
                "theme-dark",
                "pagedim-black",
                // "position-front",
                "fx-listitems-slide",
            ],
        }, {
            offCanvas: {
                pageSelector: "#page-container"
            },
        });

        var mmenuBtn = $("#mmenu-btn");
        var API = $mmenu.data("mmenu");

        mmenuBtn.click(function () {
            API.open();
            $(this).addClass('is-active')
        });

        API.bind("close:start", function () {
            setTimeout(function () {
                mmenuBtn.removeClass("is-active");
            }, 300);
        });
        /**
         * end mobile-mnu customization
         */
    }


    $('.intro-slider').owlCarousel({
        loop      : true,
        nav       : false,
        items     : 1,
        margin    : 30,
        dots      : true,
        autoHeight: false,
        // autoplay: true,
        // autoplayTimeout: 7000,
        // mouseDrag: false,
        // animateIn: "fadeIn",
        animateOut: "fadeOut",

        responsive: {
            // breakpoint from 0 up
            0: {
                touchDrag: true
            },
            // breakpoint from 768 up
            992: {
                // touchDrag: false
            }
        }

    });


    $("a[href='#popup-form-editInfo'],a[href='#popup-form-editAddr'],a[href='#popup-form-editUl']")
        .magnificPopup({
            type           : "inline",
            fixedContentPos: !1,
            fixedBgPos     : !0,
            overflowY      : "auto",
            closeBtnInside : !0,
            preloader      : !1,
            midClick       : !0,
            removalDelay   : 300,
            mainClass      : "my-mfp-zoom-in",
            callbacks      : {
                open: function () {
                    var formType = $(this.content).data('form');
                    switch (formType) {
                        case 'profile-edit-info':
                            cabinet.loadEditInfo();
                            break;
                        case 'profile-edit-address':
                            cabinet.loadEditAddr();
                            break;
                        case 'profile-edit-ul':
                            cabinet.loadEditUl();
                            break;
                        default:
                            break;
                    }
                },
            }
        });

    initProductLinksListener($('.catalog-container div.cat-item a'));


    var $grid = $('.residents-wrap').isotope({
        itemSelector: '.resident-item',
        layoutMode  : 'fitRows'
    });

    initProductCardSlider($(document));
    initProductCard($(document));


    // bind filter button click
    $('.res-filter-nav').on('click', 'button', function () {
        var filterValue = $(this).attr('data-filter');
        // use filterFn if matches value
        // filterValue = filterFns[ filterValue ] || filterValue;
        $grid.isotope({filter: filterValue});
    });

    $('.res-filter-nav').each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', 'button', function () {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
        });
    });

    // $('.product-slider').on('translate.owl.carousel', function(event) {
    //     var currentImg = $('.owl-item.active').find('.product-slide');
    //     var color = currentImg.data('fill');
    //
    //     $('#smear .svg').css('fill', color);
    // console.log(color);
    // });


    $('.bestsets-wrap').masonry({
        itemSelector: '.bestset-item',
        columnWidth : 1,
    });

    $('.blog-wrap').masonry({
        itemSelector: '.blog-item',
        columnWidth : 1,
    });

    // $('.cabinet-tabs').tabs();

    $('.preloader').fadeOut();


    /** FORMS */
    $.validate({
        form              : '.form',
        modules           : 'security',
        scrollToTopOnError: false,
    });


    $('input[type="checkbox"]').styler();
    /** END FORMS */


    $('.artist-portfolio').photoswipe();

    $('#totop').click(function () {
        $('body,html').animate({scrollTop: 0}, 600);
    });


    heightses($(document));

    $(window).on('load', function () {
        $(window).resize(function () {
            heightses($(document));
        });

        heightses($(document));
    });

    $('.rolling-filters').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('#filter-wrap').slideToggle();

        $(this).find('span').text(function (i, text) {
            return text === "Открыть фильтры" ? "Закрыть фильтры" : "Открыть фильтры";
        })
    });


    $('.product-table .btn').on('click', function () {
        // var btn = $(this),
        //     spinner = btn.siblings('.spinner-amount');
        //
        // btn.hide();
        spinner.show();

    });

    //
    $('.basket-item-del').on('click', function (e) {
        e.preventDefault();
        var item = $(this).parents('.basket-item');
        item.find('input.amount').val(0).change();
        // item.remove();

        // $('#del-notice').addClass('active');
    });

    $('#del-notice .close-notification').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('#del-notice').removeClass('active');
    });


    /**
     * YA-MAPS
     */
        //Переменная для включения/отключения индикатора загрузки
    var spinner = $('.loader');
    //Переменная для определения была ли хоть раз загружена Яндекс.Карта (чтобы избежать повторной загрузки при наведении)
    var check_if_load = false;
    //Необходимые переменные для того, чтобы задать координаты на Яндекс.Карте
    //var myMapTemp, myPlacemarkTemp;


    //Функция создания карты сайта и затем вставки ее в блок с идентификатором &#34;map-yandex&#34;
    function init() {
        var mapId = $('#map'),
            attitude = mapId.data("att"),
            longtitude = mapId.data("long"),
            zoom = mapId.data("zoom"),
            marker = mapId.data("marker"),
            map = new ymaps.Map("map", {
                center  : [attitude, longtitude],
                controls: ['zoomControl'],
                zoom    : zoom
            })
        // ,
        // //
        myPlacemark = new ymaps.Placemark(map.getCenter(), {}, {
            //     // Опции.
            //     // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            //     // Своё изображение иконки метки.
            iconImageHref: marker,
            //     // Размеры метки.
            iconImageSize: [32.768, 43],
            //     // Смещение левого верхнего угла иконки относительно
            //     // её "ножки" (точки привязки).
            iconImageOffset: [-16, -40]
        })
        ;

        map.geoObjects.add(myPlacemark);
        map.behaviors.disable('scrollZoom');


        //Если нужно сместить центр карты на странице:
        //var position = map.getGlobalPixelCenter();
        //map.setGlobalPixelCenter([ position[0] - 350, position[1] ]);

        //if ($(window).width() <= 1500) {
        //map.setGlobalPixelCenter([ position[0] - 250, position[1]]);
        //}

        // Получаем первый экземпляр коллекции слоев, потом первый слой коллекции
        var layer = map.layers.get(0).get(0);
        spinner.removeClass('is-active');
        // Решение по callback-у для определения полной загрузки карты
        waitForTilesLoad(layer).then(function () {
            // Скрываем индикатор загрузки после полной загрузки карты
            spinner.removeClass('is-active');
        });
    }


    // Функция для определения полной загрузки карты (на самом деле проверяется загрузка тайлов)
    function waitForTilesLoad(layer) {
        return new ymaps.vow.Promise(function (resolve, reject) {
            var tc = getTileContainer(layer), readyAll = true;
            tc.tiles.each(function (tile, number) {
                if (!tile.isReady()) {
                    readyAll = false;
                }
            });
            if (readyAll) {
                resolve();
            } else {
                tc.events.once("ready", function () {
                    resolve();
                });
            }
        });
    }

    function getTileContainer(layer) {
        for (var k in layer) {
            if (layer.hasOwnProperty(k)) {
                if (
                    layer[k] instanceof ymaps.layer.tileContainer.CanvasContainer
                    || layer[k] instanceof ymaps.layer.tileContainer.DomContainer
                ) {
                    return layer[k];
                }
            }
        }
        return null;
    }


    // Функция загрузки API Яндекс.Карт по требованию (в нашем случае при наведении)
    function loadScript(url, callback) {
        var script = document.createElement("script");

        if (script.readyState) {  // IE
            script.onreadystatechange = function () {
                console.log('onreadystatechange');
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {  // Другие браузеры
            script.onload = function () {
                console.log('script onload');
                callback();
            };
        }

        console.log('loadScript');
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    // Основная функция, которая проверяет когда мы навели на блок с классом &#34;ymap-container&#34;
    var ymap = function () {
        // $('.map-wrapper').on( "mouseenter", function(){
        if ($('.map-wrapper').length > 0 && !check_if_load) { // проверяем первый ли раз загружается Яндекс.Карта, если да, то загружаем

            // Чтобы не было повторной загрузки карты, мы изменяем значение переменной
            check_if_load = true;

            // Показываем индикатор загрузки до тех пор, пока карта не загрузится
            spinner.addClass('is-active');

            // Загружаем API Яндекс.Карт
            // loadScript("https://api-maps.yandex.ru/2.1/?apikey=e470b388-a1d0-4edf-acdc-34b4bc5bedee&lang=ru_RU", function () {
            // Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
            ymaps.load(init);
            // });
        }
        // });
    };

    ymap();


    /**
     * YOUTUBE SCRIPT
     */
    var tag = document.createElement('script');
    tag.src = "//www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var videoPlayers = [];
    var i = 0;

    onYouTubeIframeAPIReady = function () {
        $('.video-player .you-player').each(function () {
            var $playerID = $(this).attr("id");
            var $videoID = $(this).parents('.video-player').data("video");
            var $start = $(this).siblings('.start-video');

            $start.attr("data-playern", i);

            $start.on('click', function () {
                var playerN = $(this).attr("data-playern");
                $(this).hide();
                $(this).siblings('.you-player').show();
                $(this).siblings('.thumbnail-container').hide();


                videoPlayers[i] = new YT.Player($playerID, {
                    videoId   : $videoID,
                    playerVars: {
                        'autoplay': 0,
                        'rel'     : 0,
                        'showinfo': 0
                    },
                    events    : {
                        'onStateChange': onPlayerStateChange
                    }
                });

                if (videoPlayers[i]) {
                    var fn = function () {
                        videoPlayers[i].playVideo();
                    };
                    setTimeout(fn, 1500);
                }

            });
            i++;
        });
    };

    var p = document.getElementsByClassName("you-player");
    $(p).hide();

    onPlayerStateChange = function (event) {
        if (event.data == YT.PlayerState.ENDED) {
            $('.you-player').hide();
            $('.start-video').fadeIn('normal');
            $('.thumbnail-container').fadeIn('normal');
        }
    };
    /**
     * end YOUTUBE SCRIPT
     */

    var preorderCount = $('.preorder-product').length;
    initPreorderCard($('.catalog-wrap'));
    new Catalog(
        $('.catalog-wrap'),
        $('.filter-wrap'),
        function (element) {
            initProductLinksListener(element.find('div.cat-item a'));
            heightses(element);
            initPreorderCard(element, preorderCount);
            preorderCount = element.find('.preorder-product').length;
        }
    );


    // <BASKET>
    $('.bas-items-wrap .basket-item').each(function () {
        (new BasketItem($(this)))
            .onRefresh(initBasketItemControls)
            .onChange(onBaskChange);
    });
    $('.bas-preorder-items-wrap .basket-item').each(function () {
        (new BasketPreorderItem($(this)))
            .onChange(onBaskPreorderChange);
    });
    // new BasketNotice($('.card-popup'));
    // </BASKET>

    // <AUTH>
    var authForms = $('.registration-wrap');
    auth.hash = authForms.data('hash');
    var registerForm = $('.registration-form');
    form.initFormFields(registerForm);

    $('.do-register-btn').click(function (e) {
        e.preventDefault();
        auth.regSubmit(registerForm);
    });

    $('.do-remind-btn').click(function (e) {
        e.preventDefault();
        auth.remindSubmit($('.remind-form'));
    });

    $('.do-auth-btn').click(function (e) {
        e.preventDefault();
        auth.loginSubmit($('.auth-form'));
    });

    $('.switch-to-remind').click(function (e) {
        e.preventDefault();
        $('.auth-remind-form form.auth-form').removeClass('active-form');
        $('.auth-remind-form form.remind-form').addClass('active-form');
    });

    $('.switch-to-auth').click(function (e) {
        e.preventDefault();
        $('.auth-remind-form form.remind-form').removeClass('active-form');
        $('.auth-remind-form form.auth-form').addClass('active-form');
    });
    // </AUTH>

    $('form.form.popup-form').each(function () {
        form.initFormFields($(this));
    });

    $('.distributions-from').submit(function (e) {
        e.preventDefault();
        cabinet.distributionSubmit($(this));
    });

    $('.change-password-form').submit(function (e) {
        e.preventDefault();
        cabinet.settingsSubmit($(this));
    });

    $('.preorder-form').submit(function (e) {
        e.preventDefault();
        cabinet.preorderSubmit($(this));
    });


    var orderForm = $('.s-checkout form.form.checkout-wrap');
    form.initFormFields(orderForm);
    orderForm.find('input[name=conditions], input[name=privacy]').change(function () {
        var that = this;
        orderForm.find('input[name=' + $(this).attr('name') + ']').each(function () {
            if ($(this).prop('checked') !== $(that).prop('checked')) {
                $(this).prop('checked', $(that).prop('checked'));
                $(this).trigger('change');
            }
        });
    });
    orderForm.submit(function (e) {
        var valid = false;
        try {
            valid = cabinet.orderCheck($(this))
        } catch (error) {
            e.preventDefault();
            throw error;
        }
        if (!valid) {
            e.preventDefault();
        }
    });


    var contactsForm = $('.pcontact-item > form.form');
    form.initFormFields(contactsForm);
    contactsForm.submit(function (e) {
        e.preventDefault();
        cabinet.saveSupportRequest($(this));
    });
    contactsForm.find('input, textarea').focus(function () {
        contactsForm.find('.main-form-msg').empty();
    });

    var distributorsForm = $('.distributors-form form.form');
    form.initFormFields(distributorsForm);
    distributorsForm.submit(function (e) {
        e.preventDefault();
        cabinet.saveDistrRequest($(this));
    });
    distributorsForm.find('input').focus(function () {
        distributorsForm.find('.main-form-msg').empty();
    });

    $('.become-distributor-btn').click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(".distributors-form").offset().top
        }, 100);
    });

    var footer = $('footer.main-foot');
    var error = footer.data('error');
    if (error) {
        mainAlert.showError(error);
    }

    var toTop = $('#totop');
    checkToTop($(this).scrollTop());
    $(document).scroll(function () {
        checkToTop($(this).scrollTop());
    });

    function checkToTop(scrollTop) {
        var footerTopPosition = footer.get(0).getBoundingClientRect().top + scrollTop - window.innerHeight;
        if (scrollTop > 800) {
            if (scrollTop < footerTopPosition) {
                if (!toTop.hasClass('floating')) {
                    toTop.addClass('floating')
                }
            } else {
                toTop.removeClass('floating')
            }
        } else {
            toTop.removeClass('floating')
        }
    }

});


