var BasketNotice = (function ($) {
    function Constructor(jqItem) {
        this.element = jqItem;
        this.noticeClose = jqItem.find('.close-notification');
        this.messageElement = jqItem.find('> span');
        this.timer = null;
        this.noticeClose.click(function() {
            jqItem.removeClass('active');
        });
    }

    Constructor.prototype = $.extend(Constructor.prototype, {
        showAdd: function () {
            this.showNoticeMessage('Товар добавлен в корзину');
        },
        showUpdate: function () {
            this.showNoticeMessage('Количество товара изменено.');
        },
        showRemove: function () {
            this.showNoticeMessage('Товар удален из корзины');
        },
        showNoticeMessage: function (message) {
            this.messageElement.removeClass('error-message');
            this.messageElement.text(message);
            this.showNotice();
        },
        showErrorMessage: function (error) {
            this.messageElement.addClass('error-message');
            this.messageElement.text(error);
            this.showNotice();
        },
        showNotice: function () {
            if(null !== this.timer) {
                clearTimeout(this.timer);
            }
            this.element.addClass('active');
            this.timer = setTimeout((function () {
                this.element.removeClass('active');
                this.timer = null;
            }).bind(this), 5000)
        },
    });
    return Constructor;
})(jQuery);
