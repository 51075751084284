var PreorderCardItem = (function ($) {

    var notice = new BasketNotice($('#del-notice'));

    function Constructor(jqItem) {
        this.__initControls(jqItem);
        this.__init();
    }


    function __doRequest(type, data, success = null) {
        $.ajax({
            url: '/cabinet/cartPreorder/' + type,
            method: 'POST',
            data: data,
            dataType: 'json',
            success: (function (json) {
                if (json.data.error) {
                    __errorMsg(json.data.error);
                    return;
                } else if (null !== success) {
                    success(json);
                }
                if (null !== this.onChangeCallback) {
                    this.onChangeCallback(json.data);
                }
                return;
            }).bind(this),
            error: function () {
                __errorMsg('something went wrong!');
            }
        });
    }

    function __addToCard(count) {
        var data = {
            id: this.productId,
            props: {}
        };
        var that = this;
        data.props[this.propId] = count;
        this.__doRequest('add', data, function () {
            if (that.cartFormStock) {
                that.cartFormStock.addClass('hidden');
                that.cardFormStep.removeClass('hidden');
            }
            notice.showAdd();
        });
    }

    function __updateCard(count) {
        var data = {
            id: this.productId,
            prop: this.propId,
            quantity: count,
        };
        this.__doRequest('update', data, function (){
            notice.showUpdate();
        });
    }

    function __removeFromCard() {
        var data = {
            id: this.productId,
            prop: this.propId,
        };
        var that = this;
        this.__doRequest('delete', data, function () {
            if (that.cartFormStock) {
                that.cardFormStep.addClass('hidden');
                that.cartFormStock.removeClass('hidden');
            }

            notice.showRemove();
        });
    }

    function __changeCardCount(change) {
        var current = parseInt(this.cardInput.val());
        if (!current) {
            current = 0;
        }
        this.cardInput.val(current + change);
        this.cardInput.change();
    }

    function __errorMsg(msg) {
        alert('Error: ' + msg)
    }

    function __successMsg(msg) {
        alert('Error: ' + msg)
    }

    function __init() {
        var that = this;
        if (this.btnAdd && this.btnAdd.length > 0) {
            this.btnAdd.unbind();
            this.btnAdd.click(function (event) {
                event.stopPropagation();
                that.__changeCardCount(1);
            });
        }
        this.cardInput.unbind();
        this.cardInput.change(function () {
            var count = parseInt(that.cardInput.val());
            var doChange = true;
            if (isNaN(count)) {
                doChange = false;
                count = that.cardInput.lastValue ? that.cardInput.lastValue : 0;
                that.cardInput.val(count);
            }
            if (doChange) {
                that.cardInput.lastValue = count;
                if (null !== that.changeTimer) {
                    clearTimeout(that.changeTimer);
                }
                that.changeTimer = setTimeout(function () {
                    if (count < 1) {
                        that.__removeFromCard();
                    } else if (that.cardFormStep.hasClass('hidden')) {
                        that.__addToCard(count);
                    } else {
                        that.__updateCard(count);
                    }
                    that.changeTimer = null;
                }, 500);
            }
        });
        this.cardFormStep.unbind('click')
        this.cardFormStep.on('click', 'button', function (e) {

            var parent = $(this).parents('.preorder-control');
            var input = parent.find('.amount');
            var amount = that.cardInput.val();

            if (!parent.hasClass('table-amount')) {
                if (!$(this).is('.down')) {
                    amount++
                } else {
                    if (amount > 0) amount--
                }
            } else {
                if (!$(this).is('.down')) {
                    amount++
                } else {
                    if (amount > 0) amount--
                }

                if (parseInt(amount) === 0) {
                    input.val(1).attr('value', 1);
                }
            }

            that.cardInput.val(amount).attr('value', amount);
            that.cardInput.change();
        });
    }

    function __initControls(jqItem) {
        this.cardControls = jqItem;
        this.cardFormStep = this.cardControls.find('.preorder-control');
        this.btnAdd = this.cardControls.find('.add-to-basket-preorder-btn');
        this.cartFormStock = this.cardControls.find('.add-to-basket-preorder-btn');
        this.cardInput = this.cardFormStep.find('input.amount');
        this.productId = this.cardControls.data('product');
        this.propId = this.cardControls.data('prop');
        this.cardInput.lastValue = parseInt(this.cardInput.val());
        if (!this.cardInput.lastValue) {
            this.cardInput.lastValue = 0;
        }
    }

    Constructor.prototype.onChangeCallback = null;
    Constructor.prototype.__init = __init;
    Constructor.prototype.__initControls = __initControls;
    Constructor.prototype.__changeCardCount = __changeCardCount;
    Constructor.prototype.__removeFromCard = __removeFromCard;
    Constructor.prototype.__addToCard = __addToCard;
    Constructor.prototype.__updateCard = __updateCard;
    Constructor.prototype.__doRequest = __doRequest;
    Constructor.prototype.__errorMsg = __errorMsg;

    Constructor.prototype.onChange = function (callback) {
        this.onChangeCallback = callback;
        return this;
    };

    return Constructor;
})(jQuery);
