var BasketPreorderItem = (function ($) {
    function Constructor(jqItem) {
        this.__initControls(jqItem);
        this.__init();
        var that = this;
        this.btnRemove.click(function () {
            that.cardInput.val(0);
            that.cardInput.change();
        });

        this.cardFormStep.on('click','button',function(){
            var amount = that.cardInput.val();

            if (!that.cardFormStep.hasClass('table-amount')) {
                if (!$(this).is('.down')) {
                    amount++
                } else {
                    if (amount > 0) amount--
                }
            } else {
                if (!$(this).is('.down')) {
                    amount++
                } else {
                    if (amount > 0) amount--
                }

                if (parseInt(amount) === 0) {
                    that.cardInput.val(1).attr('value', 1);
                }
            }

            that.cardInput.val(amount).attr('value', amount);
            that.cardInput.change();
        });
    }

    function __initControls(jqItem) {
        this.cardControls = jqItem;
        this.cardFormStep = this.cardControls.find('.preorder-control');
        this.btnRemove = this.cardControls.find('.basket-item-del');
        this.cardInput = this.cardFormStep.find('input.amount');
        this.productId = this.cardControls.data('product');
        this.propId = this.cardControls.data('prop');
    }


    function __doRequest(type, data, success = null) {
        var that = this;
        $.ajax({
            url: '/cabinet/cartPreorder/' + type,
            method: 'POST',
            data: data,
            dataType: 'json',
            success: (function (json) {
                if (json.data.error) {
                    that.__errorMsg(json.data.error);
                } else if (json.data.html) {
                    var wrap = $('.basket-content-wrap');
                    wrap.html(json.data.html);
                    var callBack = this.onChangeCallback;
                    var refreshCallback = this.onRefreshCallback;
                    wrap.find('.bas-preorder-items-wrap .basket-item').each(function () {
                        (new BasketPreorderItem($(this)))
                            .onChange(callBack);
                    });

                    if(refreshCallback) {
                        refreshCallback();
                    }

                    if (callBack) {
                        callBack(json.data);
                    }
                    if (success) {
                        success(json);
                    }
                }
            }).bind(this),
            error: function () {
                that.__errorMsg('something went wrong!');
            }
        });
    }

    Constructor.prototype.onRefreshCallback = null;
    Constructor.prototype = Object.create(
        ProductCardItem.prototype,
        {constructor: {value: Constructor}}
    );
    Constructor.prototype.__initControls = __initControls;
    Constructor.prototype.__doRequest = __doRequest;
    Constructor.prototype.onRefresh = function (callback) {
        this.onRefreshCallback = callback;
        return this;
    };

    return Constructor;
})(jQuery);
