var mainAlert = new MainAlert($('.alert-message'));

/* HTML support */
var html = {
    spinnerButtonShow: function (obj, bgColor) {
        // if( bgColor == undefined ) bgColor = '#ffffff';
        // var id = Math.round( Math.random() * 9999999 );
        // var objOffset = obj.offset();
        // $('<div id="spinButtonShade_' + id + '"></div>').appendTo( obj.parent() ).
        // css('position', 'absolute').
        // offset({ 'top' : objOffset.top, 'left' : objOffset.left }).
        // width( obj.outerWidth() ).height( obj.outerHeight());
        // //addClass( 'spinnerButtonShade');//.css( 'background-color', bgColor );
        // var spinClass = 'spinnerButtonCenter';
        // $('<div id="spinButtonImage_' + id + '"><div class="loader-spinner"><div class="loader-spinner__inner"></div></div></div>').appendTo( obj.parent() ).
        // css('position', 'absolute').
        // offset({ 'top' : objOffset.top, 'left' : objOffset.left }).
        // width( obj.outerWidth() ).height( obj.outerHeight());
        // //addClass( spinClass );
        // return id;
        return 0;
    },
    spinnerButtonHide: function (id) {
        // $('#spinButtonShade_' + id).remove();
        // $('#spinButtonImage_' + id).remove();
    },
    showBigShade: function (obj, bgColor) {
        if (bgColor == undefined) bgColor = '#ffffff';
        var id = Math.round(Math.random() * 9999999);
        var objOffset = obj.offset();
        $('<div class="bigShade" id="shade' + id + '"></div>').appendTo(obj.parent()).offset({
            'top': objOffset.top,
            'left': objOffset.left
        }).width(obj.outerWidth()).height(obj.outerHeight());
        return id;
    },
    removeBigShade: function (id) {
        $('#shade' + id).remove();
    }
};
/* HTML support End */


/* HTTP support */
var http = {
    /**
     * Redirect to the url specified
     * @param url
     */
    redirect: function (url) {
        window.location = url;
    },

    /*Ajax functions*/

    /**
     * Requests url, calls method given in the returned JSON (if defined), call method given in params (if defined)
     * @param {string} url
     * @param {json} data
     * @param {function} callback
     */
    ajax: function (url, data, callback, errorHandler) {
        var type = 'GET';
        var rdata = {};
        if (data != undefined && data != null) {
            rdata = data;
            type = 'POST';
        }
        $.ajax({
            type: type,
            url: url,
            cache: false,
            data: data,
            dataType: 'json',
            success: function (json) {
                var err = false;
                var channel = false;
                if (!json) err = true;

                if (!err && json.error != undefined && json.error) {
                    error(json.error);
                } else if (!err && json.channel != undefined && json.channel && json.data != undefined && json.data) {
                    channel = true;
                    callMethod(json.channel, json.data);
                }

                if (!err && !channel && json.data != undefined && json.data && typeof (callback) == 'function') {
                    callback(json.data);
                }
            },
            error: function (jqXHR) {
                if (typeof (errorHandler) == 'function') {
                    errorHandler(jqXHR);
                } else {
                    // console.log('Status #' + jqXHR.status, jqXHR.statusText);
                }
            }
        });
    }
};

/**
 * Call method of object by name
 *
 * @param {string} methodName Method name to call
 * @param params Arguments
 */
function callMethod(methodName, params) {
    var arr = methodName.split('.');
    if (arr.length < 2) {
        if (typeof (methodName) == 'function')
            window[methodName](params);
        else if (methodName == 'redirect') http.redirect(params);
    } else {
        // todo: Make more levels possible
        window[arr[0]][arr[1]](params);
    }
}

/* HTTP support end */

/* Auth */

// For authorization forms
function label_error($obj, msg) {
    $obj.addClass('has-error');
    if (msg) {
        $('<span class="main-form-error-msg">' + msg + '</span>').insertBefore($obj);
    }
}

function label_error2($obj, msg) {
    label_error($obj, msg);
}

function removeFormErrors(form) {
    form.find('.main-form-input').removeClass('has-error');
    form.find('.main-form-error-msg').empty();
}

var auth = {
    hasErrors: false,
    remind: false,
    captchaHash: null,
    KEY_ENTER: 13,
    hash: null,
    country: 1,
    phoneMask: '',
    phoneDigits: 0,
    phoneLabel: 'Телефон ',
    phoneMaskPattern: {
        'n': {pattern: /[0-9]/},
        '?': {pattern: /[]/, optional: true},
        '0': {pattern: /[0]/},
        '1': {pattern: /[1]/},
        '2': {pattern: /[2]/},
        '3': {pattern: /[3]/},
        '4': {pattern: /[4]/},
        '5': {pattern: /[5]/},
        '6': {pattern: /[6]/},
        '7': {pattern: /[7]/},
        '8': {pattern: /[8]/},
        '9': {pattern: /[9]/},
    },

    initPhone: function (mask, digits, country, maskRow) {
        // .cleanVal() - unmasked typed value
        // .masked('19840807') - masked value programmatically
        $("#mobilePhone").val('').mask(mask,
            {translation: this.phoneMaskPattern})
            .prev()
            .text(this.phoneLabel + maskRow)
            .attr('txt', this.phoneLabel + maskRow);
        this.country = cart.country = parseInt(country);
        this.phoneMask = mask;
        this.phoneDigits = digits;
    },
    remind_slide: function () {
        if (this.remind) {
            $('#auth_block_login').removeClass('hided');
            $('#auth_block_remind').removeClass('showed');
        } else {
            var t = $('#auth_block_login').height();
            $('#auth_block_login').addClass('hided');
            $('#auth_block_remind').addClass('showed').css('top', '-' + t + 'px');
        }
        this.remind = !this.remind;
    },
    loginSubmit: function (f) {
        this.hasErrors = false;

        removeFormErrors(f);
        var authLogin = f.find('input[name=authLogin]');
        if (authLogin.val().length < 8) {
            label_error(authLogin, 'Указан неполный e-mail');
            this.hasErrors = true;
        }

        var authPass = f.find('input[name=authPassword]');
        if (authPass.val().length < 6) {
            label_error(authPass, 'Указан неполный пароль');
            this.hasErrors = true;
        }

        if (this.hasErrors) return false;

        // sending POST to the server
        var spinId = html.spinnerButtonShow($('#logButton'));
        //$( '#subtext' ).html( '' ).removeClass( 'green' );
        var data = {
            authHash: this.hash,  // Стартовый задается в шаблоне с формами
            authLogin: authLogin.val(),
            authPassword: authPass.val()
        };
        http.ajax('/auth/login/', data, function (json) {
            html.spinnerButtonHide(spinId);
            auth.hash = json.hash;
            if (json.error) {
                label_error(authLogin, json.error);
            }
        });

        return false;
    },
    selCountry: function ($li) {   // callback from select country, $li is li-object for select choice on click
        // set new mask
        auth.initPhone($li.attr('data-mask'), $li.attr('data-digits'), $li.attr('cId'), $li.attr('data-maskRow'));
    },
    remindSubmit: function (f) {
        // if (user.id != undefined && user.id) {
        //     return;
        // }
        this.hasErrors = false;

        removeFormErrors(f);
        var authLogin = f.find('input[name=authLogin]');
        if (authLogin.val().length < 8) {
            label_error(authLogin, 'Указан неполный e-mail');
            this.hasErrors = true;
        }

        if (this.hasErrors) return false;

        // sending POST to the server
        var spinId = html.spinnerButtonShow($('#remButton'));
        //$( '#subtext' ).html( '' ).removeClass( 'green' );
        var data = {
            remindHash: this.hash,
            remindData: authLogin.val()
        };
        http.ajax('/auth/remind/', data, function (json) {
            html.spinnerButtonHide(spinId);
            auth.hash = json.hash;
            if (json.error) {
                //console.log(json);
                if (json.erField)
                    label_error(f.find('input[name=' + json.erField + ']'), json.error);
            } else {
                $('.main-form-msg').html(json.html);
            }
        });
        return false;
    },
    regSubmit: function (f) {
        // if (user.id != undefined && user.id) {
        //     return;
        // }
        removeFormErrors(f);
        this.hasErrors = false;

        var regName = f.find('input[name=firstName]');
        if (regName.val().length < 2) {
            label_error(regName, 'Указано неполное имя');
            this.hasErrors = true;
        }

        var reglastName = f.find('input[name=lastName]');
        if (reglastName.val().length < 2) {
            label_error(reglastName, 'Указана неполная фамилия');
            this.hasErrors = true;
        }

        var regEmail = f.find('input[name=email]'); // Уже проверен

        var regCountry = f.find('select[name=country]');
        if (regCountry.val().length < 1) {
            label_error(regCountry, 'Не указана страна');
            this.hasErrors = true;
        }

        var regMobile = f.find('input[name=mobilePhone]');
        if (regMobile.cleanVal().length < this.phoneDigits) {
            label_error(regMobile, 'Не указан правильно телефон');
            this.hasErrors = true;
        }

        var regPas = f.find('input[name=authPassword]');
        var regPas2 = f.find('input[name=authPassword2]');
        if (regPas.val().length < 6) {
            label_error(regPas, 'Пароль меньше 6 символов');
            this.hasErrors = true;
        } else if (regPas.val() != regPas2.val()) {
            label_error(regPas2, 'Пароли не равны');
            this.hasErrors = true;
        }

        var privacy = f.find('input[name=privacy]').is(':checked') ? 1 : 0;

        if (this.hasErrors) return;

        // sending POST to the server
        var data = {
            regHash: this.hash,
            firstName: regName.val(),
            middleName: '',
            lastName: reglastName.val(),
            email: regEmail.val(),
            country: regCountry.val(),
            mobilePhone: regMobile.val(),
            fixedPhone: '',
            postCode: '',
            postStreet: '',
            postCity: '',
            postAddress: '',
            postAddon: '',
            passw: regPas.val(),
            privacy: privacy,
        };

        http.ajax('/auth/register/', data, function (json) {
            auth.hash = json.hash;
            if (json.error) {
                //console.log(json);
                if (json.erField) {
                    label_error(f.find('input[name=' + json.erField + ']'), json.error);
                } else {
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            }
        });
    },
};

var form = {
    phoneMaskPattern: {
        'n': {pattern: /[0-9]/, recursive: true},
        '?': {pattern: /[]/, optional: true},
        '0': {pattern: /[0]/},
        '1': {pattern: /[1]/},
        '2': {pattern: /[2]/},
        '3': {pattern: /[3]/},
        '4': {pattern: /[4]/},
        '5': {pattern: /[5]/},
        '6': {pattern: /[6]/},
        '7': {pattern: /[7]/},
        '8': {pattern: /[8]/},
        '9': {pattern: /[9]/},
    },
    initFormFields: function (formElement) {
        var country = formElement.find('#country option:selected');
        this.initPhoneMask(formElement, country ? country : null);
        formElement.find('.form-country-select').change(function () {
            formElement.find('input.phone-input-mask').val('');
            form.initPhoneMask(formElement, formElement.find('.form-country-select option:selected'));
        });
    },
    initPhoneMask(form, dataElement = null) {
        var inputWithMask = form.find('input.phone-input-mask');
        if (dataElement && dataElement.get(0)) {
            inputWithMask.attr('data-mask', dataElement.attr('data-mask'));
            inputWithMask.attr('data-row', dataElement.attr('data-row'));
            inputWithMask.attr('data-digits', dataElement.attr('data-digits'));
        }
        inputWithMask
            .mask(inputWithMask.attr('data-mask'), {translation: this.phoneMaskPattern})
            .attr('placeholder', inputWithMask.attr('data-row'));
    }
};

function isEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function isInn(i) {
    if (!i)
        return true;
    if (i.match(/\D/)) return false;
    var inn = i.match(/(\d)/g);
    if (i === '000000000000')
        return false;
    if (inn.length === 10) {
        return inn[9] === String(((
            2 * inn[0] + 4 * inn[1] + 10 * inn[2] +
            3 * inn[3] + 5 * inn[4] + 9 * inn[5] +
            4 * inn[6] + 6 * inn[7] + 8 * inn[8]
        ) % 11) % 10);
    } else if (inn.length === 12) {
        return inn[10] === String(((
            7 * inn[0] + 2 * inn[1] + 4 * inn[2] +
            10 * inn[3] + 3 * inn[4] + 5 * inn[5] +
            9 * inn[6] + 4 * inn[7] + 6 * inn[8] +
            8 * inn[9]
        ) % 11) % 10) && inn[11] === String(((
            3 * inn[0] + 7 * inn[1] + 2 * inn[2] +
            4 * inn[3] + 10 * inn[4] + 3 * inn[5] +
            5 * inn[6] + 9 * inn[7] + 4 * inn[8] +
            6 * inn[9] + 8 * inn[10]
        ) % 11) % 10);
    }

    return false;
}

function isBankBik(bik) {
    var result = false;
    if (typeof bik === 'number') {
        bik = bik.toString();
    } else if (typeof bik !== 'string') {
        bik = '';
    }
    if (bik.length && /[0-9]/.test(bik) && bik.length === 9) {
        result = true;
    }
    return result;
}

function isBankRs(rs, bik) {
    var result = false;
    if (isBankBik(bik)) {
        if (typeof rs === 'number') {
            rs = rs.toString();
        } else if (typeof rs !== 'string') {
            rs = '';
        }
        if (rs.length && /[0-9]/.test(rs) && rs.length === 20) {
            var bikRs = bik.toString().slice(-3) + rs;
            var checksum = 0;
            var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
            for (var i in coefficients) {
                checksum += coefficients[i] * (bikRs[i] % 10);
            }
            if (checksum % 10 === 0) {
                result = true;
            }
        }
    }
    return result;
}

function isBankKs(ks, bik) {
    var result = false;
    if (isBankBik(bik)) {
        if (typeof ks === 'number') {
            ks = ks.toString();
        } else if (typeof ks !== 'string') {
            ks = '';
        }
        if (ks.length && /[0-9]/.test(ks) && ks.length === 20) {
            var bikKs = '0' + bik.toString().slice(4, 6) + ks;
            var checksum = 0;
            var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
            for (var i in coefficients) {
                checksum += coefficients[i] * (bikKs[i] % 10);
            }
            if (checksum % 10 === 0) {
                result = true;
            }
        }
    }
    return result;
}

function isOgrn(ogrn) {
    ogrn += '';
    console.log(ogrn.length);
    console.log((ogrn.slice(12, 13) == ((ogrn.slice(0, -1)) % 11 + '').slice(-1)));
    console.log(ogrn.slice(12, 13));
    console.log((ogrn.slice(0, -1)) % 11 + '');
    console.log(((ogrn.slice(0, -1)) % 11 + '').slice(-1));
    if (ogrn.length == 13 &&
        (ogrn.slice(12, 13) == ((ogrn.slice(0, -1)) % 11 + '').slice(-1))) {
        return true;
    } else if (ogrn.length == 15 &&
        (ogrn.slice(14, 15) == ((ogrn.slice(0, -1)) % 13 + '').slice(-1))) {
        return true;
    } else
        return false;
}

function isKpp(kpp) {
    var result = false;
    if (typeof kpp === 'number') {
        kpp = kpp.toString();
    } else if (typeof kpp !== 'string') {
        kpp = '';
    }
    if (kpp.length && kpp.length === 9 && /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
        result = true;
    }
    return result;
}

/* Cabinet */
var cabinet = {
    datepickerFormat: {
        changeMonth: true,
        changeYear: true,
        dateFormat: "dd.mm.yy",
        defaultDate: "-10y",
        minDate: "-30y",
        maxDate: "-1",
        yearRange: "-30:+0",
        firstDay: 1,
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        dayNamesShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
        dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        monthNamesShort: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        //monthNamesShort: [ "Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек" ],
    },
    hasErrors: false,
    profilePage: 'info',    // info, editInfo, editAddr, editUl
    preorderSubmit: function (f) {      // Сохранение - предзаказа
        // if (user.id == undefined || !user.id) return;
        removeFormErrors(f);
        var spinId = html.spinnerButtonShow(f.find('.main-form-btn .btn'));
        var data = {
            action: 'preorder',
            preorder: f.find('input[name=preorder]').is(":checked") ? 1 : 0
        };
        http.ajax('/cabinet/settings/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
        });
        return false;
    },
    settingsSubmit: function (f) {      // Сохранение настройки - смена пароля
        this.hasErrors = false;
        removeFormErrors(f);

        var password = f.find('input[name=password]');
        if (password.val().length < 6) {
            label_error2(password, 'Указан неполный пароль');
            this.hasErrors = true;
        } else {
            var passwordNew = f.find('input[name=passwordNew]');
            var passwordNewRepeat = f.find('input[name=passwordNewRepeat]');
            if (passwordNew.val().length < 6) {
                label_error2(passwordNew, 'Указан неполный пароль');
                this.hasErrors = true;
            } else if (passwordNew.val() == password.val()) {
                label_error2(passwordNew, 'Не используйте старый пароль');
                this.hasErrors = true;
            } else if (passwordNew.val() != passwordNewRepeat.val()) {
                label_error2(passwordNewRepeat, 'Пароли не совпадают');
                this.hasErrors = true;
            }
        }

        if (this.hasErrors) return false;

        var spinId = html.spinnerButtonShow($('#settinsButton'));
        var data = {
            action: 'password',
            password: password.val(),
            passwordNew: passwordNew.val(),
            passwordNewRepeat: passwordNewRepeat.val()
        };
        http.ajax('/cabinet/settings/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
        });
        return false;
    },
    distributionSubmit: function (f) {      // Сохранение - новости и уведомления - подписка
        // if (user.id == undefined || !user.id) return;
        removeFormErrors(f);
        var spinId = html.spinnerButtonShow(f.find('.main-form-btn .btn'));
        var data = {
            action: 'distribution',
            distributionSMS: f.find('input[name=distributionSms]').is(":checked") ? 1 : 0,
            distributionMail: f.find('input[name=distributionMail]').is(":checked") ? 1 : 0
        };
        http.ajax('/cabinet/settings/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
        });
        return false;
    },
    loadEditInfo: function () {     // Загрузка формы редактирования информации пользователя

        var spinId = html.spinnerButtonShow($('#loadEditInfoButton'));
        var data = {
            action: 'loadEditInfo',
        };
        http.ajax('/cabinet/profile/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.html) {
                cabinet.EditBlockInit(json, 'editInfo');
            }
        });
        return false;
    },
    loadEditAddr: function () {     // Загрузка формы редактирования адреса доставки пользователя
        // if (user.id == undefined || !user.id) return;

        // var spinId = html.spinnerButtonShow($('#loadEditAddrButton'));
        var data = {
            action: 'loadEditAddr',
        };
        http.ajax('/cabinet/profile/', data, function (json) {
            // html.spinnerButtonHide(spinId);
            if (json.html) {
                cabinet.EditBlockInit(json, 'editAddr');
            }
        });
        return false;
    },
    loadEditUl: function () {     // Загрузка формы редактирования информации юр.лица
        // if (user.id == undefined || !user.id) return;

        // var spinId = html.spinnerButtonShow($('#loadEditUlButton'));
        var data = {
            action: 'loadEditUl',
        };
        http.ajax('/cabinet/profile/', data, function (json) {
            // html.spinnerButtonHide(spinId);
            if (json.html) {
                cabinet.EditBlockInit(json, 'editUl');
            }
        });
        return false;
    },
    EditBlockInit: function (json, pg) {   // Инит блока редактирования
        // $('#profileInfoBlock').hide();
        // $('#profileEditBlock').html(json.html).show();
        var content = $('#popup-form-' + pg);
        content.html(json.html);
        form.initFormFields(content);
        cabinet.profilePage = pg;
        $('.contacts-save-form').submit(function (e) {
            e.preventDefault();
            cabinet.saveEditInfo($(this));
        });
        $('.address-save-form').submit(function (e) {
            e.preventDefault();
            cabinet.saveEditAddr($(this));
        });
        $('.requisites-save-form').submit(function (e) {
            e.preventDefault();
            cabinet.saveEditUl($(this));
        });

        // initFuncs.startAjaxFoms();
    },
    EditBlockBack: function (e) {    // Возврат из блока редактирования
        e.stopPropagation();
        $('#profileEditBlock').hide().html('');
        $('#profileInfoBlock').show();
        cabinet.profilePage = 'info';
    },
    saveEditAddr: function (f) {     // Сохранение адреса доставки пользователя
        // if (user.id == undefined || !user.id) return;
        this.hasErrors = false;
        removeFormErrors(f);

        var postCity = f.find('input[name=postCity]');
        if (postCity.val().length < 2) {
            label_error2(postCity, 'Укажите город/населенный пункт');
            this.hasErrors = true;
        }

        var postCode = f.find('input[name=postCode]');
        if (postCode.val().length < 2 || postCode.val().length > 10) {
            label_error2(postCode, 'Укажите почтовый индекс правильно');
            this.hasErrors = true;
        }

        var postStreet = f.find('input[name=postStreet]');
        if (postStreet.val().length < 3) {
            label_error2(postStreet, 'Укажите улицу полностью');
            this.hasErrors = true;
        }

        var postAddress = f.find('input[name=postAddress]');
        if (postAddress.val().length < 1) {
            label_error2(postAddress, 'Не указаны данные');
            this.hasErrors = true;
        }

        // if (this.hasErrors) return false;

        if (!this.hasErrors) {
            var spinId = html.spinnerButtonShow($('#saveEditAddrButton'));
            var data = {
                hash: f.find('input[name=hash]').val(),
                action: 'saveEditAddr',
                postCity: postCity.val(),
                postCode: postCode.val(),
                postStreet: postStreet.val(),
                postAddress: postAddress.val(),
            };
            http.ajax('/cabinet/profile/', data, function (json) {
                html.spinnerButtonHide(spinId);
                if (json.error) {
                    if (json.erField)
                        label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                    else {  // В общее место
                        f.find('span.afterAjaxSubmitMess').removeClass('success').addClass('error').text(json.error);
                    }
                } else if (json.success) {    // Успех
                    f.find('span.afterAjaxSubmitMess').removeClass('error').addClass('success').text(json.success);
                }
            });
        }
        return false;
    },
    saveEditInfo: function (f) {     // Сохранение контактов пользователя
        this.hasErrors = false;
        // if (user.id == undefined || !user.id) return;
        removeFormErrors(f);

        form.initFormFields(f);

        var postCountry = f.find('select[name=countryId]');
        if (!postCountry.val() || postCountry.val() < 1) {
            label_error2(postCountry, 'Укажите страну');
            this.hasErrors = true;
        }

        var firstName = f.find('input[name=firstName]');
        if (firstName.val().length < 2) {
            label_error2(firstName, 'Укажите ваше имя');
            this.hasErrors = true;
        }
        var middleName = f.find('input[name=middleName]');

        var lastName = f.find('input[name=lastName]');
        if (lastName.val().length < 3) {
            label_error2(lastName, 'Укажите вашу фамилию');
            this.hasErrors = true;
        }

        var regEmail = f.find('input[name=email]'); // Уже проверен

        var mobilePhone = f.find('input[name=mobilePhone]');
        if (mobilePhone.length > 0 && mobilePhone.cleanVal().length < mobilePhone.attr('data-digits')) {
            label_error(mobilePhone, 'Не указан правильно телефон');
            this.hasErrors = true;
        }

        if (this.hasErrors) return false;

        var spinId = html.spinnerButtonShow($('#saveEditInfoButton'));
        var data = {
            hash: f.find('input[name=hash]').val(),
            action: 'saveEditInfo',
            country: postCountry.val(),
            firstName: firstName.val(),
            middleName: middleName.val(),
            lastName: lastName.val(),
            email: regEmail.val(),
            mobilePhone: mobilePhone.val(),
        };
        http.ajax('/cabinet/profile/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('span.afterAjaxSubmitMess').removeClass('success').addClass('error').text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('span.afterAjaxSubmitMess').removeClass('error').addClass('success').text(json.success);
            }
        });
        return false;
    },
    saveEditUl: function (f) {     // Сохранение данных по юр.лицу
        var msg = '';
        // if (user.id == undefined || !user.id) return false;
        removeFormErrors(f);
        var ul = true;

        var company = f.find('input[name=company]');
        console.log(company);
        if (ul) {
            if (company.val().length < 4) {
                label_error2(company, msg = 'Укажите полное название организации');
                return false;
            }
        }

        var OGRN = f.find('input[name=OGRN]');
        if (ul) {
            if (OGRN.val().length > 0 && !isOgrn(OGRN.val())) {
                label_error2(OGRN, msg = 'Укажите правильный ОГРН / ОГРНИП');
                return false;
            }
        }

        var INN = f.find('input[name=INN]');
        if (INN.val().length > 0 && !isInn(INN.val())) {
            label_error2(INN, msg = 'Укажите правильный ИНН');
            return false;
        }

        var KPP = f.find('input[name=KPP]');
        if (ul) {
            if (KPP.val().length > 0 && !isKpp(KPP.val())) {
                label_error2(KPP, msg = 'Укажите правильный КПП');
                return false;
            }
        }

        var passSerial = f.find('input[name=passSerial]');
        var passNumber = f.find('input[name=passNumber]');
        var passIssueDate = f.find('input[name=passIssueDate]');
        var passUnit = f.find('input[name=passUnit]');
        var passUnitCode = f.find('input[name=passUnitCode]');

        var BIK = f.find('input[name=BIK]');
        if (BIK.val().length > 0 && !isBankBik(BIK.val())) {
            label_error2(BIK, msg = 'Укажите правильный БИК');
            return false;
        }
        var BIKis = BIK.val().length > 0;

        var currentAccount = f.find('input[name=currentAccount]');
        if ((!BIKis && currentAccount.val().length > 0) ||
            (currentAccount.val().length > 0 && !isBankRs(currentAccount.val(), BIK.val()))) {
            label_error2(currentAccount, msg = 'Укажите правильный Р/с');
            return false;
        }

        var corrAccount = f.find('input[name=corrAccount]');
        if ((!BIKis && corrAccount.val().length > 0) ||
            (corrAccount.val().length > 0 && !isBankKs(corrAccount.val(), BIK.val()))) {
            label_error2(corrAccount, msg = 'Укажите правильный К/с');
            return false;
        }

        var companyAddrFl = f.find('input[name=companyAddrFl]').val();
        var companyAddrUl = f.find('input[name=companyAddrUl]').val();
        var companyAddr = '';
        if (ul) companyAddr = companyAddrUl; else companyAddr = companyAddrFl;

        var directorTitle = f.find('input[name=directorTitle]').val();
        var directorNameFull = f.find('input[name=directorNameFull]').val();
        var directorNameShort = f.find('input[name=directorNameShort]').val();
        var OKPO = f.find('input[name=OKPO]').val();

        var bankName = f.find('input[name=bankName]').val();

        var spinId = html.spinnerButtonShow($('#saveEditUlButton'));
        var data = {
            ul: ul ? 1 : 0,
            hash: f.find('input[name=hash]').val(),
            action: 'saveEditUl',
            company: company.val(),
            companyAddr: companyAddr,
            directorTitle: directorTitle,
            directorNameFull: directorNameFull,
            directorNameShort: directorNameShort,
            BIK: BIK.val(),
            OGRN: OGRN.val(),
            INN: INN.val(),
            KPP: KPP.val(),
            currentAccount: currentAccount.val(),
            corrAccount: corrAccount.val(),
            bankName: bankName,
            OKPO: OKPO,
            passSerial: passSerial.val(),
            passNumber: passNumber.val(),
            passIssueDate: passIssueDate.val(),
            passUnit: passUnit.val(),
            passUnitCode: passUnitCode.val(),
        };
        http.ajax('/cabinet/profile/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
        });
        return false;
    },
    saveDistrRequest: function (f) {     // Отправка заявки на дистрибьюторство
        this.hasErrors = false;
        var fio = f.find('input[name=fio]');
        removeFormErrors(f);
        if (fio.val().length < 2) {
            label_error2(fio, 'Укажите, как вас зовут');
            this.hasErrors = true;
        }

        var phone = f.find('input[name=phone]');
        if (phone.val().length < 7) {
            label_error2(phone, 'Укажите контактный телефон полностью');
            this.hasErrors = true;
        }

        var email = f.find('input[name=email]'); // Уже проверен

        var company = f.find('input[name=company]');
        if (company.val().length < 2) {
            label_error2(company, 'Укажите название компании');
            this.hasErrors = true;
        }

        var city = f.find('input[name=city]');
        if (city.val().length < 2) {
            label_error2(city, 'Укажите город');
            this.hasErrors = true;
        }

        var privacy = f.find('input[name=privacy]').is(':checked') ? 1 : 0;

        if (this.hasErrors) return false;

        var spinId = html.spinnerButtonShow($('#saveDistrRequestButton'));
        var data = {
            hash: f.find('input[name=hash]').val(),
            fio: fio.val(),
            email: email.val(),
            phone: phone.val(),
            company: company.val(),
            city: city.val(),
            privacy: privacy,
        };
        http.ajax('/distributors/distrrequest/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField)
                    label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
            f.find('input').val('');
        });
        return false;
    },
    saveSupportRequest: function (f) {     // Отправка запроса на поддержку из Контактов
        this.hasErrors = false;
        removeFormErrors(f);
        var fio = f.find('input[name=fio]');
        if (fio.val().length < 2) {
            label_error2(fio, 'Укажите, как вас зовут');
            this.hasErrors = true;
        }

        var phone = f.find('input[name=phone]');
        if (phone.val().length < 7) {
            label_error2(phone, 'Укажите контактный телефон полностью');
            this.hasErrors = true;
        }

        var email = f.find('input[name=email]'); // Уже проверен

        var messtheme = f.find('input[name=messtheme]');
        if (messtheme.val().length < 2) {
            label_error2(messtheme, 'Укажите тему сообщения');
            this.hasErrors = true;
        }

        var mess = f.find('textarea[name=mess]')[0];
        if (mess.value.length < 10) {
            label_error2($(mess), 'Длина сообщения от 10 символов');
            this.hasErrors = true;
        }

        var privacy = f.find('input[name=privacy]').is(':checked') ? 1 : 0;

        if (this.hasErrors) return false;

        var spinId = html.spinnerButtonShow($('#saveSupportRequestButton'));
        var data = {
            hash: f.find('input[name=hash]').val(),
            fio: fio.val(),
            email: email.val(),
            phone: phone.val(),
            messtheme: messtheme.val(),
            privacy: privacy,
            mess: mess.value
        };

        http.ajax('/contacts/supportrequest/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField) {
                    if (json.erField == 'mess') {
                        label_error2($(f.find('textarea[name=mess]')[0]), json.error);
                    } else {
                        label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                    }
                } else {  // В общее место
                    f.find('.main-form-msg')
                        .removeClass('main-form-success-msg')
                        .addClass('main-form-error-msg')
                        .text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('.main-form-msg')
                    .removeClass('main-form-error-msg')
                    .addClass('main-form-success-msg')
                    .text(json.success);
                f.find('input[name=hash]').val('');
            }
            f.find('input, textarea').val('');
        });
        return false;
    },
    saveFaqRequest: function (f) {     // Отправка запроса на поддержку из FAQ
        this.hasErrors = false;
        var fio = f.find('input[name=fio]');
        if (fio.val().length < 2) {
            label_error2(fio, 'Укажите, как вас зовут');
            this.hasErrors = true;
        }

        var email = f.find('input[name=email]'); // Уже проверен

        var mess = f.find('textarea[name=mess]')[0];
        if (mess.value.length < 10) {
            label_error2($(mess), 'Длина сообщения от 10 символов');
            this.hasErrors = true;
        }

        if (this.hasErrors) return false;

        var spinId = html.spinnerButtonShow($('#saveFaqRequestButton'));
        var data = {
            hash: f.find('input[name=hash]').val(),
            fio: fio.val(),
            email: email.val(),
            mess: mess.value
        };

        http.ajax('/faq/faqask/', data, function (json) {
            html.spinnerButtonHide(spinId);
            if (json.error) {
                if (json.erField) {
                    if (json.erField == 'mess')
                        label_error2($(f.find('textarea[name=mess]')[0]), json.error);
                    else
                        label_error2(f.find('input[name=' + json.erField + ']'), json.error);
                } else {  // В общее место
                    f.find('span.afterAjaxSubmitMess').removeClass('success').addClass('error').text(json.error);
                }
            } else if (json.success) {    // Успех
                f.find('span.afterAjaxSubmitMess').removeClass('error').addClass('success').text(json.success);
                f.find('input[name=hash]').val('');
            }
        });
        return false;
    },
    orderCheckErrors: {
        country: 'Укажите страну',
        postCity: 'Пожалуйста, укажатие город/населенный пункт',
        postCode: 'указан недействительный почтовый индекс',
        postStreet: 'Укажите улицу',
        postAddress: 'Укажите дом, строение/корпус, квартиру',
        firstName: 'Вы не указали Ваше имя',
        lastName: 'Вы не указали Вашу фамилию',
        mobilePhone: 'Вы не указали номер мобильного телефона',
        email: 'Неверный формат e-mail адреса',
        conditions: 'Необходимо ваше согласие с условиями доставки и оплаты',
        privacy: 'Необходимо ваше согласие с на обработку данных',
    },
    orderCheck: function (f) {
        removeFormErrors(f);
        var postCountry = f.find('select[name=country]');
        if (!postCountry.val() || postCountry.val() < 1) {
            mainAlert.showError(cabinet.orderCheckErrors.country);
            label_error2(postCountry, cabinet.orderCheckErrors.country);
            return false;
        }

        var postCity = f.find('input[name=postCity]');
        if (postCity.val().length < 3) {
            mainAlert.showError(cabinet.orderCheckErrors.postCity);
            label_error2(postCity, cabinet.orderCheckErrors.postCity);
            // postCity.focus();
            return false;
        }

        var postCode = $('input[name=postCode]');
        if (postCode.val().length < 3) {
            mainAlert.showError(cabinet.orderCheckErrors.postCode);
            label_error2(postCode, cabinet.orderCheckErrors.postCode);
            return false;
        }

        var postStreet = f.find('input[name=postStreet]');
        if (postStreet.val().length < 2) {
            mainAlert.showError(cabinet.orderCheckErrors.postStreet);
            label_error2(postStreet, cabinet.orderCheckErrors.postStreet);
            return false;
        }

        var postAddress = f.find('input[name=postAddress]');
        if (postAddress.val().length < 2) {
            mainAlert.showError(cabinet.orderCheckErrors.postAddress);
            label_error2(postAddress, cabinet.orderCheckErrors.postAddress);
            return false;
        }

        var firstName = f.find('input[name=firstName]');
        if (firstName.val().length < 2) {
            mainAlert.showError(cabinet.orderCheckErrors.firstName);
            label_error2(firstName, cabinet.orderCheckErrors.firstName);
            return false;
        }

        var lastName = f.find('input[name=lastName]');
        if (lastName.val().length < 2) {
            mainAlert.showError(cabinet.orderCheckErrors.lastName);
            label_error2(lastName, cabinet.orderCheckErrors.lastName);
            return false;
        }

        var mobilePhone = f.find('input[name=mobilePhone]');
        if (mobilePhone.val().length < 10) {
            mainAlert.showError(cabinet.orderCheckErrors.mobilePhone);
            label_error2(mobilePhone, cabinet.orderCheckErrors.mobilePhone);
            return false;
        }

        var email = f.find('input[name=email]');
        if (!isEmail(email.val())) {
            mainAlert.showError(cabinet.orderCheckErrors.email);
            label_error2(email, cabinet.orderCheckErrors.email);
            return false;
        }

        var condition = f.find('input[name=conditions]');
        if (condition.prop('checked') === false) {
            mainAlert.showError(cabinet.orderCheckErrors.conditions);
            return false;
        }
        var privacy = f.find('input[name=privacy]');
        if (privacy.prop('checked') === false) {
            mainAlert.showError(cabinet.orderCheckErrors.privacy);
            return false;
        }

        if (postCode.val().length < 4 || postStreet.val().length < 3 ||
            postAddress.val().length < 1) {
            mainAlert.showError('укажите индекс и адрес полностью');
        }


        return true;
    },
};
