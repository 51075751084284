var Catalog = (function ($) {
    function Constructor(jqItem, jqFilter, afterRequestCallback) {
        this.element = jqItem;
        this.category = jqItem.data('category');
        this.timer = null;
        this.afterRequestCallback = afterRequestCallback;
        // var filterCounter = jqItem.find('.catalog-filter-counter');
        var that = this;
        // jqItem.find('.catalog-filter .brand-items .main-form-input-checkbox').change(function () {
        //     that.changeFilter('brand', $(this));
        //     var cnt = parseInt(filterCounter.text());
        //     cnt += $(this).is(':checked') ? 1 : -1;
        //     filterCounter.text(cnt);
        // });
        jqFilter.find('.filter-search-query input').keyup(function () {
            var value = $(this).val();
            if (value.length > 2) {
                that.filter = {query: value};
                jqFilter.find('.filter-item .filter-checkbox.checked').removeClass('checked');
                that.reloadCatalogItems();
            } else if (!value) {
                delete that.filter.query;
                that.reloadCatalogItems();
            }
        });

        jqFilter.find('.filter-item .filter-checkbox').change(function () {
            if ('INPUT' === this.tagName) {
                that.changeFilter('prop', $(this));
            }
            // var cnt = parseInt(filterCounter.text());
            // cnt += $(this).is(':checked') ? 1 : -1;
            // filterCounter.text(cnt);
        });

        // $('.catalog-filter .brand-items .main-form-input-checkbox:checked').each(function () {
        //     that.setFilterParam('brand', $(this));
        // });

        jqFilter.find('.filter-item .filter-checkbox:checked').each(function () {
            that.setFilterParam('prop', $(this));
        });

        this.refreshBottom();
        this.initPreorderPopup();
        if (this.bottomSide > $(window).height()) {
            $(window).scroll((function () {
                var windowBottom = $(window).scrollTop() + $(window).height();
                if (windowBottom >= this.bottomSide) {
                    this.loadNextPage();
                }
            }).bind(this));
        }
    }

    Constructor.prototype = (function () {
        return {
            processChanges: true,
            filter: {},
            loading: false,
            loaded: false,
            page: 1,
            bottomSide: null,
            constructor: Constructor,
            changeFilter: function (type, checkbox) {
                this.loaded = false;
                this.page = 1;
                this.setFilterParam(type, checkbox);
                this.reloadCatalogItems();
            },
            setFilterParam: function (type, checkbox) {
                var add = checkbox.prop('checked');
                var id = checkbox.data('id');
                if (this.filter.hasOwnProperty(type)) {
                    var props = this.filter[type].split(':');
                    var has = null;
                    for (var i = 0; i < props.length; i++) {
                        if (parseInt(id) === parseInt(props[i])) {
                            has = i;
                            break;
                        }
                    }
                    if (add) {
                        props.push(id);
                    } else if (null !== has) {
                        props.splice(has, 1);
                    }
                    if (props.length > 0) {
                        this.filter[type] = props.join(':');
                    } else {
                        delete this.filter[type];
                    }
                } else {
                    if (add) {
                        this.filter[type] = '' + checkbox.data('id');
                    } else {
                        delete this.filter[type];
                    }
                }
            },
            reloadCatalogItems: function () {
                var paramsQuery = $.param(this.filter);
                if (paramsQuery) {
                    paramsQuery = '?' + paramsQuery;
                }
                history.pushState(
                    null,
                    null,
                    location.protocol + '//' + location.host + location.pathname
                    + paramsQuery);
                $.ajax({
                    url: '/catalogAjax' + `${this.category ? `/${this.category}` : ''}`, // + '?' + this.buildFilterString(),
                    method: 'GET',
                    data: this.filter,
                    dataType: 'json',
                    success: (function (json) {
                        this.element.html(json.data.html);
                        this.refreshBottom();
                        this.initPreorderPopup();
                        this.afterRequestCallback(this.element);
                    }).bind(this),
                    error: function () {
                        alert('Ошибка загрузки каталога')
                    }
                });
            },
            refreshBottom: function () {
                var productsContainer = this.element;
                if (productsContainer.get(0)) {
                    this.bottomSide = productsContainer.offset().top + productsContainer.height() - 300;
                }
            },
            initPreorderPopup: function () {
                $('.preorder-btn').magnificPopup({
                    type: 'inline',
                    preloader: false
                });

            },
            loadNextPage: function () {
                if (this.loading || this.loaded) {
                    return;
                }
                this.loading = true;
                this.page++;
                var data = Object.assign({page: this.page}, this.filter);
                $.ajax({
                    url: '/catalogAjax' + `${this.category ? `/${this.category}` : ''}`,
                    method: 'GET',
                    data: data,
                    dataType: 'json',
                    success: (function (json) {
                        this.element.append(json.data.html);
                        this.loading = false;
                        this.refreshBottom();
                        this.initPreorderPopup();
                        if (json.data.count < 1) {
                            this.loaded = true;
                        }
                        this.afterRequestCallback(this.element);
                    }).bind(this),
                    error: (function () {
                        alert('Ошибка загрузки каталога');
                        this.loading = false;
                    }).bind(this)
                });
            },
        };
    })();

    return Constructor;
})(jQuery);
