var MainAlert = (function ($) {
    function Constructor(jqItem) {
        this.element = jqItem;
        this.textElement = jqItem.find('.alert-message__text');
        this.closeBtn = jqItem.find('.alert-message__close');
        this.closeBtn.click((function () {
            this.clear();
        }).bind(this));
        this.timer = null;
    }

    Constructor.prototype = $.extend(Constructor.prototype, {
        clear: function () {
            this.element.removeClass('alert-message_fixed');
            this.element.removeClass('alert-message_error');
            this.element.removeClass('alert-message_success');
            this.textElement.empty();
        },
        showError: function (message) {
            this.textElement.text(message);
            this.element.addClass('alert-message_error');
            this.showNotice();
        },
        showSuccess: function (message) {
            this.textElement.text(message);
            this.element.addClass('alert-message_success');
            this.showNotice();
        },
        showNotice: function () {
            if (null !== this.timer) {
                clearTimeout(this.timer);
            }
            this.element.addClass('alert-message_fixed');
            this.timer = setTimeout((function () {
                this.clear();
                this.timer = null;
            }).bind(this), 10000)
        },
    });
    return Constructor;
})(jQuery);
